<template>
  <div class="page-userInfo">
    <div class="li-box">
      <div class="title">基本信息</div>
      <div class="container info-container flex flex-start">
        <div class="avatar">
          <img class="avatar" src="@/assets/imgs/user.png" alt="">
        </div>
        <div class="info-box flex flex-between flex-wrap">
          <div class="info-li">
            <span class="title">姓名：</span>
            <span class="info">{{ userInfo.name }}</span>
          </div>
          <div class="info-li">
            <span class="title">账号：</span>
            <span class="info">{{ userInfo.account }}</span>
          </div>
          <div class="info-li" v-if="userInfo.asSocial">
            <span class="title">身份：</span>
            <span class="info">{{ userInfo.identity}}</span>
          </div>
          <div class="info-li">
            <span class="title">状态：</span>
            <span class="info">{{ userInfo.deleted === 0 ? '正常' : '异常' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="li-box" v-if="userInfo.from==='us'&&userInfo.account!=='expert'">
      <div class="title">账号安全</div>
      <div class="container safe-container">
        <div class="li flex flex-between">
          <div class="title">登录密码</div>
          <div class="buttons">
            <el-button type="primary" size="small" @click="clickChangePasswordBtn">修改密码</el-button>
          </div>
        </div>
        <div class="li flex flex-between">
          <div class="title">绑定邮箱 <span class="email">{{ userEmail }}</span></div>
          <div class="buttons">
            <el-button type="primary" size="small" @click="clickChangeEmailBtn">修改邮箱</el-button>
          </div>
        </div>
      </div>
    </div>
    <!--弹出框-修改密码-->
    <el-dialog
        title="修改密码"
        :visible.sync="changePassword.dialog"
        width="500px"
        center
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form label-width="150px" ref="changePasswordForm" :model="changePassword" :rules="changePassword.rule">
          <el-form-item prop="password" label="请输入原密码：">
            <el-input placeholder="请输入" v-model="changePassword.password" type="password"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"></el-input>
          </el-form-item>
          <el-form-item prop="password1" label="请输入新密码：">
            <el-input placeholder="请输入" maxlength="12" v-model="changePassword.password1"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                      type="password"></el-input>
          </el-form-item>
          <el-form-item prop="password2" label="请再次输入新密码：">
            <el-input placeholder="请输入" maxlength="12" v-model="changePassword.password2"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                      type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="default" @click="changePassword.dialog=false">取 消</el-button>
        <el-button type="primary" @click="clickChangePasswordSureBtn">确 认</el-button>
      </div>
    </el-dialog>
    <!--弹出框-修改邮箱-->
    <el-dialog
        title="修改邮箱"
        :visible.sync="changeEmail.dialog"
        width="600px"
        center
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form label-width="150px" ref="changeEmailForm" :model="changeEmail" :rules="changeEmail.rule">
          <el-form-item prop="email" label="请输入新邮箱：">
            <el-input placeholder="请输入" v-model="changeEmail.email"></el-input>
          </el-form-item>
          <el-form-item prop="code" label="请输入验证码：">
            <div class="flex flex-start">
              <el-input placeholder="请输入" maxlength="6" v-model="changeEmail.code"
              ></el-input>
              <el-button style="margin-left: 10px" :disabled="changeEmail.send" type="text" size="small"
                         @click="clickSendEmailCodeBtn">发送验证码至邮箱
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="default" @click="changeEmail.dialog=false">取 消</el-button>
        <el-button type="primary" @click="clickChangeEmailSureBtn">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {regCheck} from "../../utils/common";
import elDragDialog from "@/directive/el-drag-dialog";
import {mapState} from "vuex";
import {msg_err, msg_success} from "../../utils/ele_component";
import {UserModel} from "../../model/UserModel";

export default {
  name: "UserInfo",
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  directives: {
    elDragDialog
  },
  data() {
    // 密码输入检测
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("请输入6-12位密码"))
      }
      callback()
    }
    // 密码输入检测
    const validatePassword2 = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("请再次输入新密码"))
      }
      if (this.changePassword.password1) {
        if (this.changePassword.password1 !== value) {
          callback(new Error("新密码不一致"))
        }
      }
      callback()
    }
    // 邮箱输入检测
    const validateEmail = (rule, value, callback) => {
      if (!regCheck("email", value)) {
        callback(new Error("请输入正确的邮箱地址"))
      }
      callback()
    }
    // 邮箱验证码检测
    const validateCode = (rule, value, callback) => {
      let reg = /^[0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('请输入验证码'))
      }
      if (value.length !== 6 || isNaN(Math.ceil(value))) {
        callback(new Error("请输入6位数字验证码"))
      }
      callback()
    }
    return {
      userEmail: "",
      changePassword: {
        dialog: false,
        password: "",
        password1: "",
        password2: "",
        rule: {
          password: {required: true, trigger: 'blur', message: '请输入密码'},
          password1: {required: true, trigger: 'blur', validator: validatePassword},
          password2: {required: true, trigger: 'blur', validator: validatePassword2},
        }
      },
      changeEmail: {
        dialog: false,
        email: "",
        code: "",
        send: false,
        rule: {
          code: {required: true, trigger: 'blur', validator: validateCode},
          email: {required: true, trigger: 'blur', validator: validateEmail}
        }
      }
    }
  },
  mounted() {
    this.userEmail = this.userInfo.email
  },
  methods: {
    // 点击修改密码按钮
    async clickChangePasswordBtn() {
      this.changePassword.dialog = true
      this.changePassword.password = ""
      this.changePassword.password1 = ""
      this.changePassword.password2 = ""
      setTimeout(() => {
        this.$refs.changePasswordForm.clearValidate();
      }, 300)
    },
    // 点击修改密码弹窗-确认按钮
    async clickChangePasswordSureBtn() {
      this.$refs.changePasswordForm.validate(async valid => {
        if (valid) {
          if (await UserModel.changePassword(
              this.changePassword.password,
              this.changePassword.password1
          )) {
            msg_success("修改密码成功")
            this.changePassword.dialog = false
          }
        }
      });
    },
    // 点击修改邮箱按钮
    async clickChangeEmailBtn() {
      this.changeEmail.dialog = true
      this.changeEmail.email = ""
      this.changeEmail.code = ""
      this.changeEmail.send = false;
      setTimeout(() => {
        this.$refs.changeEmailForm.clearValidate();
      }, 300)
    },
    // 点击发送邮箱验证码按钮
    async clickSendEmailCodeBtn() {
      let email = this.changeEmail.email;
      if (!email) {
        msg_err("请先输入您的邮箱地址")
        return
      }
      if (!regCheck("email", email)) {
        msg_err("邮箱格式错误")
        return;
      }
      await UserModel.sendBindEmail(this.changeEmail.email)
      this.changeEmail.send = true;
    },
    // 点击修改邮箱弹窗-确认按钮
    async clickChangeEmailSureBtn() {
      this.$refs.changeEmailForm.validate(async valid => {
        if (valid) {
          if (await UserModel.changeEmail(
              this.changeEmail.email,
              this.changeEmail.code
          )) {
            msg_success("修改邮箱成功")
            this.changeEmail.dialog = false
            this.userEmail = this.changeEmail.email
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

.page-userInfo {

}

.li-box {
  margin-bottom: 30px;

  > div.title {
    font-size: 18px;
    color: #333;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .container {
    padding: 0px 20px;
  }
}

.info-container {
  color: #333;

  div.avatar {
    width: 150px;

    img.avatar {
      width: 110px;
      height: 110px;
    }
  }

  .info-box {
    width: 700px;
    font-size: 15px;

    .info-li {
      width: 350px;
      margin-bottom: 10px;

      span.title {
        color: #666;
      }

      span.info {
        color: #888;
      }
    }
  }

}

.safe-container {
  .li {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      border-bottom: none;
    }

    .title {
      font-size: 15px;
      color: #555;
    }

    .email {
      color: #888;
      margin-left: 15px;
      font-size: 14px;
    }
  }
}
</style>
